import React, { useCallback, useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useAuth } from "../../common/providers/user.provider";
import FormInputField from "../../components/form-input.component";
import Button from "../../components/button.component";
import LoadingSpinner from "../../components/loading-spinner.component";
import SelectField from "../../components/select-field.component";
import FormTextField from "../../components/form-text.component";
import { DropEvent, FileRejection, useDropzone } from "react-dropzone";
import { useScrollToTop } from "../../common/hooks/use-scroll-to-top";
import { useNavigate } from "react-router-dom";
import { VIEWS } from "../../routes/routes";
import { UserRoles } from "../../common/enums/user-roles.enum";
import useToken from "../../hooks/useToken";
import Testimonials from "../../components/testimonials.component";
import BrandsBanner from "../../components/brands-banner.compoment";
import Footer from "../../components/footer.component";
import homeBannerImg from "../../common/assets/images/home_banner.png";
import FooterDashboard from "../../components/footer-dashboard.component";

export default function Dashboard() {
  useScrollToTop();
  const navigate = useNavigate();
  const { token, setToken, clearToken } = useToken();
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [metrics, setMetrics] = useState<any | null>({
    totalEvents: 0,
    totalEquipments: 0,
    totalLessons: 0,
    totalQuizzes: 0,
  });

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    const storedUser = JSON.parse(localStorage.getItem("user") || "");
    if (accessToken && (user || storedUser)) {
      handleGetAllMetrics(accessToken);
    }
  }, []);

  // =====================================================================================================================
  // GET ALL ITEMS FUNCTIONALITY
  // =====================================================================================================================

  const handleGetAllMetrics = async (token: any) => {
    try {
      // setIsLoading(true);
      // // Calling to the API service
      // const response = await getAllMetrics(token);
      // // Handling the response
      // if (response.success) {
      //   setMetrics({
      //     totalEvents: response?.data?.data?.totalEvents,
      //     totalEquipments: response?.data?.data?.totalEquipments,
      //     totalLessons: response?.data?.data?.totalLessons,
      //     totalQuizzes: response?.data?.data?.totalQuizzes,
      //   });
      // } else {
      //   toast.error(response.error);
      // }
      // setIsLoading(false);
    } catch (error) {
      console.error("Error during form action:", error);
      setIsLoading(false);
    }
  };

  return (
    <React.Fragment>
      <div className="flex flex-col min-h-screen">
        <div className="w-full flex flex-col justify-center items-center min-h-auto mt-12 bg-gradient-bottom-top">
          <h4 className="text-xl text-secondary-dark text-center font-semibold">
            Vikalp : HIRING SUITE FOR RECRUITERS
          </h4>
          <h4 className="text-4xl text-primary-dark text-center font-semibold mt-4">
            From Campus to Senior Level Hiring
          </h4>
          <h4 className="text-3xl text-primary-light text-center font-normal mt-3 mb-20">
            Bouquet of solution to meet all your hiring needs
          </h4>
          <img
            src={homeBannerImg}
            alt="Vikalp"
            className="mt-16 md:mt-32 lg:mt-0"
          />
        </div>
        <Testimonials />
      </div>
      <BrandsBanner />
      <FooterDashboard />
      {isLoading ? <LoadingSpinner /> : null}
      <ToastContainer />
    </React.Fragment>
  );
}
