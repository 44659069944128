import React, { useCallback, useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useAuth } from "../../common/providers/user.provider";
import Button from "../../components/button.component";
import LoadingSpinner from "../../components/loading-spinner.component";
import { useScrollToTop } from "../../common/hooks/use-scroll-to-top";
import { useNavigate, useSearchParams } from "react-router-dom";
import useToken from "../../hooks/useToken";
import FooterDashboard from "../../components/footer-dashboard.component";
import ResumeDetails from "../../components/dashboard/resume-details-card.component";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import {
  deleteResume,
  getResumeById,
  updateResume,
} from "../../services/resumes-api.service";
import { VIEWS } from "../../routes/routes";
import PopupConfirmationComponent from "../../components/popup-confirmation.component";

export default function UpdateResume() {
  useScrollToTop();
  const [searchParams] = useSearchParams();
  const resumeId: string = searchParams.get("id")?.toString() || "";
  const navigate = useNavigate();
  const { token } = useToken();
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [resumeData, setResumeData] = useState({
    id: "",
    candidateName: "",
    email: "",
    phoneNumber: "",
    location: "",
    file: null,
    file_format: "",
    totalExperience: 0,
    salary: 0,
    expectedSalary: 0,
    profileSummary: "",
    keySkills: [],
  });
  const [allDetailsInResume, setAllDetailsInResume] = useState(null);

  useEffect(() => {
    if (token && resumeId) {
      handleGetResumeData(resumeId);
    }
  }, [token, resumeId]);

  const handleGetResumeData = async (resumeId: any) => {
    if (!resumeId) {
      toast.error("Resume ID not defined.");
      return;
    }
    try {
      setIsLoading(true);

      // Calling the API service
      const response = await getResumeById(token, resumeId);

      // Handling the response
      if (response.success) {
        setResumeData({
          id: response?.data?.data?._id,
          candidateName: response?.data?.data?.candidateName,
          email: response?.data?.data?.email,
          phoneNumber: response?.data?.data?.phoneNumber,
          location: response?.data?.data?.location,
          file: response?.data?.data?.file,
          file_format: response?.data?.data?.file_format,
          totalExperience: response?.data?.data?.totalExperience,
          salary: response?.data?.data?.salary,
          expectedSalary: response?.data?.data?.expectedSalary,
          profileSummary: response?.data?.data?.profileSummary,
          keySkills: response?.data?.data?.keySkills,
        });
        setAllDetailsInResume(response?.data?.data);
      } else {
        toast.error(response.error);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error during form action:", error);
      setIsLoading(false);
    }
  };

  const formatNumber = (value: number | null | undefined): string => {
    return value !== null && value !== undefined
      ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      : "";
  };

  const unFormatNumber = (value: string): number => {
    return value ? parseFloat(value.replace(/,/g, "")) : 0;
  };

  // Add this useEffect to log updated resumeData
  useEffect(() => {
    console.log(
      "URL : ",
      `${process.env.REACT_APP_S3_BUCKET_URL}/${resumeData?.file}`
    );
  }, [resumeData]);

  // =====================================================================================================================
  // UPDATE FUNCTIONALITY
  // =====================================================================================================================
  const handleUpdateResume = async () => {
    try {
      setIsLoading(true);

      // Update resume data with the edited information
      const response = await updateResume(token, resumeData?.id, resumeData);

      if (response.success) {
        toast.success("Saved changes successfully");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        toast.error(response.error);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error during update resume :", error);
      setIsLoading(false);
    }
  };

  // =====================================================================================================================
  // DELETE FUNCTIONALITY
  // =====================================================================================================================

  const [isConfirmationOpen, setConfirmationOpen] = useState<boolean>(false);

  const handleConfirmDeleteItem = async () => {
    setConfirmationOpen(true);
  };

  const handleDeleteResume = async () => {
    try {
      setIsLoading(true);

      // Update resume data with the edited information
      const response = await deleteResume(token, resumeData?.id);

      if (response.success) {
        setConfirmationOpen(false);
        toast.success(response?.data?.message);
        setTimeout(() => {
          navigate("/user/results?page=1&pageSize=5");
        }, 1250);
      } else {
        toast.error(response.error);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error during update resume :", error);
      setIsLoading(false);
    }
  };

  // Performing the skills CRUD operations
  const [newSkill, setNewSkill] = useState("");

  const handleAddSkill = () => {
    if (newSkill.trim()) {
      setResumeData((prev: any) => ({
        ...prev,
        keySkills: [...prev.keySkills, newSkill.trim()],
      }));
      setNewSkill(""); // Clear input field
    } else {
      toast.error("Skill cannot be empty.");
    }
  };

  const handleDeleteSkill = (index: any) => {
    setResumeData((prev: any) => ({
      ...prev,
      keySkills: prev?.keySkills?.filter((_: any, i: any) => i !== index),
    }));
  };

  const handleEditSkill = (index: any, updatedSkill: any) => {
    setResumeData((prev: any) => {
      const updatedSkills = [...prev.keySkills];
      updatedSkills[index] = updatedSkill;
      return { ...prev, keySkills: updatedSkills };
    });
  };

  return (
    <React.Fragment>
      <div className="lg:px-24 lg:pt-16 min-h-screen">
        <div className="flex justify-between items-center w-full">
          <button
            onClick={() => navigate(-1)}
            className="flex items-center px-4 py-2 bg-blue-800 hover:bg-blue-700 text-white font-medium rounded-md shadow-md  focus:outline-none focus:ring-2 focus:ring-offset-2 transition-colors duration-300"
          >
            <i className="fa-solid fa-chevron-left mr-2"></i> Go back
          </button>
        </div>
        <div className="bg-slate-50 rounded-xl w-full shadow-md min-h-screen mt-16 p-8 grid grid-cols-2 gap-4">
          <div className="p-5 bg-slate-200 h-full rounded-lg flex justify-center items-center">
            {resumeData?.file_format === ".pdf" ? (
              <iframe
                src={`${process.env.REACT_APP_S3_BUCKET_URL}/${resumeData?.file}`}
                width="100%"
                height="100%"
                title="Resume Preview"
              ></iframe>
            ) : resumeData?.file_format === ".docx" ||
              resumeData?.file_format === ".doc" ? (
              <DocViewer
                documents={[
                  {
                    uri: `${process.env.REACT_APP_S3_BUCKET_URL}/${resumeData?.file}`,
                  },
                ]}
                pluginRenderers={DocViewerRenderers}
                style={{ width: "100%", height: "100%" }}
              />
            ) : (
              <div className="text-slate-400 font-normal text-lg flex flex-col items-center justify-center">
                <i className="fa-regular fa-face-meh-blank text-6xl mb-2"></i>
                <p>Preview not available</p>
              </div>
            )}
          </div>
          <div className="p-5 h-full">
            <div className="bg-white w-full p-6 rounded-xl shadow-sm mb-6">
              <p className="text-slate-700 text-sm font-medium mb-4">
                Update the details
              </p>
              <div className="mb-4">
                <label className="block text-slate-700 text-sm font-medium mb-1">
                  Name
                </label>
                <input
                  type="text"
                  className="w-full p-2 border border-slate-300 rounded-md shadow-sm text-slate-700 focus:border-blue-500 focus:ring-blue-500"
                  value={resumeData.candidateName}
                  onChange={(e) =>
                    setResumeData({
                      ...resumeData,
                      candidateName: e.target.value,
                    })
                  }
                />
              </div>
              <div className="mb-4">
                <label className="block text-slate-700 text-sm font-medium mb-1">
                  Email
                </label>
                <input
                  type="email"
                  className="w-full p-2 border border-slate-300 rounded-md shadow-sm text-slate-700 focus:border-blue-500 focus:ring-blue-500"
                  value={resumeData.email}
                  onChange={(e) =>
                    setResumeData({ ...resumeData, email: e.target.value })
                  }
                />
              </div>
              <div className="mb-4">
                <label className="block text-slate-700 text-sm font-medium mb-1">
                  Phone
                </label>
                <input
                  type="tel"
                  className="w-full p-2 border border-slate-300 rounded-md shadow-sm text-slate-700 focus:border-blue-500 focus:ring-blue-500"
                  value={resumeData.phoneNumber}
                  onChange={(e) =>
                    setResumeData({
                      ...resumeData,
                      phoneNumber: e.target.value,
                    })
                  }
                />
              </div>
              <div className="mb-4">
                <label className="block text-slate-700 text-sm font-medium mb-1">
                  Location
                </label>
                <input
                  type="text"
                  className="w-full p-2 border border-slate-300 rounded-md shadow-sm text-slate-700 focus:border-blue-500 focus:ring-blue-500"
                  value={resumeData.location}
                  onChange={(e) =>
                    setResumeData({ ...resumeData, location: e.target.value })
                  }
                />
              </div>
              <div className="mb-4">
                <label className="block text-slate-700 text-sm font-medium mb-1">
                  Total Experience
                </label>
                <input
                  type="number"
                  className="w-full p-2 border border-slate-300 rounded-md shadow-sm text-slate-700 focus:border-blue-500 focus:ring-blue-500"
                  value={resumeData.totalExperience}
                  onChange={(e) =>
                    setResumeData({
                      ...resumeData,
                      totalExperience: parseFloat(e.target.value),
                    })
                  }
                />
              </div>
              <div className="mb-4">
                <label className="block text-slate-700 text-sm font-medium mb-1">
                  Salary
                </label>
                <input
                  type="text"
                  className="w-full p-2 border border-slate-300 rounded-md shadow-sm text-slate-700 focus:border-blue-500 focus:ring-blue-500"
                  value={formatNumber(resumeData.salary)}
                  onChange={(e) =>
                    setResumeData({
                      ...resumeData,
                      salary: unFormatNumber(e.target.value),
                    })
                  }
                />
              </div>
              <div className="mb-4">
                <label className="block text-slate-700 text-sm font-medium mb-1">
                  Expected Salary
                </label>
                <input
                  type="text"
                  className="w-full p-2 border border-slate-300 rounded-md shadow-sm text-slate-700 focus:border-blue-500 focus:ring-blue-500"
                  value={formatNumber(resumeData.expectedSalary)}
                  onChange={(e) =>
                    setResumeData({
                      ...resumeData,
                      expectedSalary: unFormatNumber(e.target.value),
                    })
                  }
                />
              </div>
              <div className="mb-4">
                <label className="block text-slate-700 text-sm font-medium mb-1">
                  Profile Summary
                </label>
                <textarea
                  rows={5}
                  className="w-full p-2 border border-slate-300 rounded-md shadow-sm text-slate-700 focus:border-blue-500 focus:ring-blue-500 resize-none"
                  maxLength={400}
                  value={resumeData.profileSummary}
                  onChange={(e) =>
                    setResumeData({
                      ...resumeData,
                      profileSummary: e.target.value,
                    })
                  }
                />
              </div>
              <div className="mb-4">
                <label className="block text-slate-700 text-sm font-medium mb-1">
                  Key Skills
                </label>
                <div className="flex flex-wrap gap-2 p-2 border border-slate-300 rounded-md shadow-sm bg-white">
                  {resumeData.keySkills.map((skill, index) => (
                    <div
                      key={index}
                      className="flex items-center gap-2 px-3 py-1 bg-blue-100 text-blue-700 rounded-full text-sm shadow-sm"
                    >
                      <span>{skill}</span>
                      <button
                        onClick={() => handleDeleteSkill(index)}
                        className="text-red-500 hover:text-red-700 focus:outline-none"
                      >
                        &times;
                      </button>
                    </div>
                  ))}
                  <input
                    type="text"
                    value={newSkill}
                    onChange={(e) => setNewSkill(e.target.value)}
                    onKeyDown={(e) => e.key === "Enter" && handleAddSkill()}
                    placeholder="Add a skill"
                    className="flex-grow p-2 text-sm border-none outline-none text-slate-700"
                  />
                </div>
                <p className="text-xs text-slate-500 mt-2">
                  Press <kbd>Enter</kbd> to add a skill.
                </p>
              </div>
              <div className="flex flex-row justify-end items-center">
                <Button
                  name={"Update Resume"}
                  handleAction={handleUpdateResume}
                />
                <button
                  className="w-1/2 h-14 bg-white border-2 border-rose-500 hover:bg-rose-600 text-rose-500 hover:text-slate-50 text-md font-bold py-2 px-4 rounded-2xl transition duration-300 cursor-pointer ml-5"
                  onClick={handleConfirmDeleteItem}
                >
                  <i className="fa-regular fa-trash-can"></i> Delete resume
                </button>
              </div>
            </div>
            <ResumeDetails data={allDetailsInResume} />
          </div>
        </div>
      </div>
      <div className="h-32"></div>
      <FooterDashboard />
      {isLoading ? <LoadingSpinner /> : null}
      <ToastContainer />
      Delete pop up goes here
      <PopupConfirmationComponent
        popUpDisplay={isConfirmationOpen}
        handleClose={() => setConfirmationOpen(false)}
        handleOpen={() => setConfirmationOpen(true)}
        popUpTitle={"Warning"}
        popUpContent={"Are you sure want to delete this resume?"}
        handleConfirm={() => handleDeleteResume()}
      />
    </React.Fragment>
  );
}
