import React, { useEffect, useState } from "react";

interface SimpleKeywordInputProps {
  initialKeywords?: string[];
  onUpdateKeywords: (keywords: string[]) => void;
}

const SimpleKeywordInput: React.FC<SimpleKeywordInputProps> = ({
  initialKeywords = [],
  onUpdateKeywords,
}) => {
  const [keywords, setKeywords] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState("");

  // Update keywords state when initialKeywords prop changes
  useEffect(() => {
    if (JSON.stringify(initialKeywords) !== JSON.stringify(keywords)) {
      setKeywords(initialKeywords);
    }
  }, [initialKeywords]);

  const addKeyword = () => {
    if (inputValue.trim() === "") return;

    // Check for duplicate keywords
    if (
      keywords.some(
        (keyword) => keyword.toLowerCase() === inputValue.trim().toLowerCase()
      )
    ) {
      return; // Ignore duplicates
    }

    const updatedKeywords = [...keywords, inputValue.trim()];
    setKeywords(updatedKeywords);

    // Update parent component state
    onUpdateKeywords(updatedKeywords);

    setInputValue(""); // Clear the input field
  };

  const removeKeyword = (index: number) => {
    const updatedKeywords = keywords.filter((_, i) => i !== index);
    setKeywords(updatedKeywords);

    // Update parent component state
    onUpdateKeywords(updatedKeywords);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent form submission if inside a form
      addKeyword();
    }
  };

  const clearAll = () => {
    setKeywords([]);
    onUpdateKeywords([]);
  };

  return (
    <div className="w-full border border-gray-300 p-3 rounded-lg">
      <div className="flex flex-wrap gap-2 mb-2">
        {keywords.map((keyword, index) => (
          <div
            key={index}
            className="flex items-center bg-gray-100 border border-gray-300 rounded-full px-3 py-1"
          >
            <span className="text-gray-700">{keyword}</span>
            <button
              type="button"
              className="ml-2 text-red-500 hover:text-red-700"
              onClick={() => removeKeyword(index)}
              aria-label="Remove keyword"
            >
              ✕
            </button>
          </div>
        ))}
      </div>
      <input
        type="text"
        className="w-full px-3 py-2 border border-gray-300 rounded-lg"
        placeholder="Type a exclude keyword and press Enter"
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyDown={handleKeyPress}
      />
      <div className="flex justify-end mt-2">
        <button
          type="button"
          onClick={clearAll}
          className="text-gray-500 hover:text-blue-700 text-sm"
        >
          Clear all
        </button>
      </div>
    </div>
  );
};

export default SimpleKeywordInput;
