import React, { useEffect, useState } from "react";

interface Keyword {
  text: string;
  isMandatory: boolean;
}

interface KeywordInputProps {
  initialKeywords?: string[];
  initialMandatoryKeywords?: string[];
  onUpdateKeywords: (
    normalKeywords: string[],
    mandatoryKeywords: string[]
  ) => void;
}

const KeywordInputInputNormalMandatory: React.FC<KeywordInputProps> = ({
  initialKeywords = [],
  initialMandatoryKeywords = [],
  onUpdateKeywords,
}) => {
  const [keywords, setKeywords] = useState<Keyword[]>([]);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    // Combine initialKeywords and initialMandatoryKeywords, removing duplicates manually
    const allKeywordTexts = [...initialKeywords, ...initialMandatoryKeywords];
    const uniqueKeywords = allKeywordTexts.filter(
      (item, index) => allKeywordTexts.indexOf(item) === index
    );

    const allKeywords = uniqueKeywords.map((text) => ({
      text,
      isMandatory: initialMandatoryKeywords.includes(text),
    }));

    setKeywords(allKeywords);
  }, [initialKeywords, initialMandatoryKeywords]);

  const addKeyword = () => {
    if (inputValue.trim() === "") return;

    if (
      keywords.some(
        (keyword) =>
          keyword.text.toLowerCase() === inputValue.trim().toLowerCase()
      )
    )
      return;

    const newKeyword: Keyword = { text: inputValue.trim(), isMandatory: false };
    const updatedKeywords = [...keywords, newKeyword];
    setKeywords(updatedKeywords);
    updateParent(updatedKeywords);
    setInputValue("");
  };

  const toggleMandatory = (index: number) => {
    const updatedKeywords = keywords.map((keyword, i) =>
      i === index ? { ...keyword, isMandatory: !keyword.isMandatory } : keyword
    );
    setKeywords(updatedKeywords);
    updateParent(updatedKeywords);
  };

  const removeKeyword = (index: number) => {
    const updatedKeywords = keywords.filter((_, i) => i !== index);
    setKeywords(updatedKeywords);
    updateParent(updatedKeywords);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      addKeyword();
    }
  };

  const updateParent = (updatedKeywords: Keyword[]) => {
    const mandatoryKeywords = updatedKeywords
      .filter((keyword) => keyword.isMandatory)
      .map((keyword) => keyword.text);

    const normalKeywords = updatedKeywords
      .filter((keyword) => !keyword.isMandatory)
      .map((keyword) => keyword.text);

    onUpdateKeywords(normalKeywords, mandatoryKeywords);
  };

  const clearAll = () => {
    setKeywords([]);
    updateParent([]);
  };

  return (
    <div className="w-full border border-gray-300 p-3 rounded-lg">
      <div className="flex flex-wrap gap-2 mb-2">
        {keywords.map((keyword, index) => (
          <div
            key={index}
            className="flex items-center bg-gray-100 border border-gray-300 rounded-full px-3 py-1"
          >
            <button
              type="button"
              className={`mr-2 ${
                keyword.isMandatory ? "text-blue-500" : "text-gray-400"
              }`}
              onClick={() => toggleMandatory(index)}
              aria-label={
                keyword.isMandatory
                  ? "Unmark as mandatory"
                  : "Mark as mandatory"
              }
            >
              ★
            </button>
            <span className="text-gray-700">{keyword.text}</span>
            <button
              type="button"
              className="ml-2 text-red-500 hover:text-red-700"
              onClick={() => removeKeyword(index)}
              aria-label="Remove keyword"
            >
              ✕
            </button>
          </div>
        ))}
      </div>
      <input
        type="text"
        className="w-full px-3 py-2 border border-gray-300 rounded-lg"
        placeholder="Type keyword and press Enter"
        value={inputValue}
        onChange={(e) => {
          setInputValue(e.target.value);
        }}
        onKeyDown={handleKeyPress}
      />
      <div className="flex justify-end mt-2">
        <button
          type="button"
          onClick={clearAll}
          className="text-gray-500 hover:text-blue-700 text-sm"
        >
          Clear all
        </button>
        {/* Optional: Remove the "Get Keywords" button since parent state is updated on each action */}
      </div>
    </div>
  );
};

export default KeywordInputInputNormalMandatory;
