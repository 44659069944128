import axios, { AxiosProgressEvent, AxiosResponse } from "axios";
import { ENDPOINT } from "./api-endpoints.routes";

// Create save search record API call
export const createSaveSearchRecord = async (
  token: any,
  saved_type: string,
  search_params: string,
  search_name: string
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json", // If you sending only the JSON data use this format
    },
    timeout: 500000,
  };
  try {
    const response = await axios.post(
      `${ENDPOINT.SAVED_SEARCH.ROOT}`,
      { saved_type, search_params, search_name },
      config
    );
    return { success: true, data: response.data };
  } catch (error: any) {
    return {
      success: false,
      error: error.response?.data?.message || error.message,
    };
  }
};

// Getting the saved search data by ID API call
export const getSaveSearchRecords = async (token: any, search_type: string) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json", // If you sending the files use this format
    },
    timeout: 5000,
  };
  try {
    const response = await axios.get(
      `${ENDPOINT.SAVED_SEARCH.ROOT_WITH_SEARCH_TYPE(search_type)}`,
      config
    );
    return { success: true, data: response.data };
  } catch (error: any) {
    return {
      success: false,
      error: error.response?.data?.message || error.message,
    };
  }
};

// Delete save search record API call
export const deleteSaveSearchRecord = async (token: any, recordId: string) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json", // If you sending only the JSON data use this format
    },
    timeout: 500000,
  };
  try {
    const response = await axios.delete(
      `${ENDPOINT.SAVED_SEARCH.ROOT_WITH_ID(recordId)}`,
      config
    );
    return { success: true, data: response.data };
  } catch (error: any) {
    return {
      success: false,
      error: error.response?.data?.message || error.message,
    };
  }
};
