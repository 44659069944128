// import { useEffect, useState } from "react";

// interface UseTokenResult {
//   token: string | null;
//   setToken: (newToken: string) => void;
//   clearToken: () => void;
// }

// const useToken = (): UseTokenResult => {
//   const [token, setToken] = useState<string | null>(null);

//   useEffect(() => {
//     const storedToken = localStorage.getItem("accessToken");
//     if (storedToken) {
//       setToken(storedToken);
//     }
//   }, []);

//   // Function to manually set the token
//   const setNewToken = (newToken: string) => {
//     setToken(newToken);
//     localStorage.setItem("accessToken", newToken);
//   };

//   // Function to clear the token
//   const clearToken = () => {
//     setToken(null);
//     localStorage.removeItem("accessToken");
//   };

//   return { token, setToken: setNewToken, clearToken };
// };

// export default useToken;

// Handling with the expiration time
import { useEffect, useState } from "react";
import { decodeJwt } from "../common/utils/decode-jwt";
import { VIEWS } from "../routes/routes";

interface UseTokenResult {
  token: string | null;
  setToken: (newToken: string) => void;
  clearToken: () => void;
}

const useToken = (): UseTokenResult => {
  const [token, setToken] = useState<string | null>(null);

  useEffect(() => {
    const storedToken = localStorage.getItem("accessToken");
    if (storedToken) {
      setToken(storedToken);
      // Check for token expiration immediately when the app loads
      checkTokenExpiration(storedToken);
    }

    // Periodically check if the token is expired
    const intervalId = setInterval(() => {
      if (token) {
        checkTokenExpiration(token);
      }
    }, 60000); // Check every minute

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [token]);

  // Function to decode and check token expiration
  const checkTokenExpiration = (token: string) => {
    const decodedToken = decodeJwt(token);
    const expirationTime = decodedToken?.exp * 1000; // JWT expiration is in seconds, so multiply by 1000
    const currentTime = Date.now();

    if (expirationTime && currentTime > expirationTime) {
      clearToken();
    }
  };

  // Function to manually set the token
  const setNewToken = (newToken: string) => {
    setToken(newToken);
    localStorage.setItem("accessToken", newToken);
  };

  // Function to clear the token
  const clearToken = () => {
    setToken(null);
    localStorage.removeItem("accessToken");
    localStorage.removeItem("user");
    window.location.href = `${VIEWS.HOME}`;
  };

  return { token, setToken: setNewToken, clearToken };
};

export default useToken;
