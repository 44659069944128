import React, { useEffect, useState } from "react";
import LoadingSpinner from "../../components/loading-spinner.component";
import { toast, ToastContainer } from "react-toastify";
import { useAuth } from "../../common/providers/user.provider";
import { UserRoles } from "../../common/enums/user-roles.enum";
import { useScrollToTop } from "../../common/hooks/use-scroll-to-top";
import { useNavigate } from "react-router-dom";
import useToken from "../../hooks/useToken";
import {
  formatTimestamp,
  formatTimestampHtmlDateInput,
} from "../../common/utils/date-format";
import { Paginator } from "primereact/paginator";
import FormPopupComponent from "../../components/form-popup.component";
import FormInputField from "../../components/form-input.component";
import FormTextField from "../../components/form-text.component";
import Button from "../../components/button.component";
import { truncateText } from "../../common/utils/truncate-paragraghs";
import {
  createCompany,
  deleteCompany,
  getAllCompanies,
} from "../../services/comapnies-api.service";
import PopupConfirmationComponent from "../../components/popup-confirmation.component";

export default function AdminCompanies() {
  useScrollToTop();
  const navigate = useNavigate();
  const { token, setToken, clearToken } = useToken();
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [companies, setCompanies] = useState<any | null>(null);
  const [company, setCompany] = useState<any | null>(null);
  const [companyId, setCompanyId] = useState<string>("");
  const [showNoContentMessage, setShowNoContentMessage] =
    useState<boolean>(false);

  // Handling the pagination
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(5);
  const [total, setTotal] = useState<number>(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortField, setSortField] = useState("createdAt");
  const [sortOrder, setSortOrder] = useState("asc"); // 'asc' or 'desc'

  //PrimeReact paginator handler
  const onPageChange = (event: any) => {
    const newPage = event.page + 1;
    const newPageSize = event.rows;
    setPageSize(newPageSize);
    setCurrentPage(newPage);
  };

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    const storedUser = JSON.parse(localStorage.getItem("user") || "");
    if (accessToken && (user || storedUser)) {
      handleGetAllCompanies(accessToken);
    }
    const timeoutId = setTimeout(
      () => {
        setShowNoContentMessage(true);
      },
      companies?.length === 0 ? 0 : 100
    );
    return () => clearTimeout(timeoutId);
  }, [currentPage, pageSize]);

  // =====================================================================================================================
  // GET ALL ITEMS FUNCTIONALITY
  // =====================================================================================================================

  const handleGetAllCompanies = async (token: any) => {
    try {
      setIsLoading(true);
      // Calling to the API service
      const response = await getAllCompanies(
        token,
        currentPage,
        pageSize,
        searchQuery
      );
      // Handling the response
      if (response.success) {
        setCompanies(response?.data?.data?.items);
        setTotal(response?.data?.data?.pageInfo?.totalDocuments);
      } else {
        toast.error(response.error);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error during form action:", error);
      setIsLoading(false);
    }
  };

  // =====================================================================================================================
  // SEARCH FUNCTIONALITY
  // =====================================================================================================================
  useEffect(() => {
    // Getting the token from the local storage
    const accessToken = localStorage.getItem("accessToken");

    // Stopping trigger this functionality when the page mount
    if (searchQuery.trim() === "") {
      handleGetAllCompanies(accessToken);
    } else {
      const debounceSearch = setTimeout(() => {
        handleSearchCompanies(accessToken);
      }, 750);
      return () => clearTimeout(debounceSearch);
    }
  }, [searchQuery]); // Only run this effect when searchQuery changes

  const handleSearchCompanies = async (accessToken: any) => {
    try {
      setIsLoading(true);
      const response = await getAllCompanies(
        accessToken,
        currentPage,
        pageSize,
        searchQuery
      );
      if (response.success) {
        setCompanies(response?.data?.data?.items);
        setTotal(response?.data?.data?.pageInfo?.totalDocuments);
      } else {
        toast.error(response.error);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error during form action:", error);
      setIsLoading(false);
    }
  };

  const handleSearchKeyUp = (e: any) => {
    setSearchQuery(e.target.value);
  };

  // =====================================================================================================================
  // CREATE ITEM FUNCTIONALITY
  // =====================================================================================================================
  const [isAddFormOpen, setIsAddFormOpen] = useState<boolean>(false);

  const [formData, setFormData] = useState({
    name: "",
    description: "",
  });

  // Clearing the form data when opening the create form
  const handleCreateItem = async () => {
    setIsAddFormOpen(!isAddFormOpen);
    setFormData({
      name: "",
      description: "",
    });
    setIsAddFormOpen(true);
    setIsItemUpdate(false);
    setIsExistImageDisplay(false);
  };

  //Handling the text input
  const handleInputChange = (name: string, value: any) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle create form action
  const handleFormAction = async (e: any) => {
    e.preventDefault();
    const { name, description } = formData;

    if (name === "") {
      toast.error("Please enter name");
    } else if (description === "") {
      toast.error("Please enter description");
    } else {
      try {
        setIsLoading(true);

        // Create FormData object to handle file upload
        const formData = new FormData();

        formData.append("name", name);
        formData.append("description", description);

        // Calling to the API service
        const response = await createCompany(token, formData);

        // Handling the response
        if (response.success) {
          setFormData({
            name: "",
            description: "",
          });
          setIsAddFormOpen(false);
          handleGetAllCompanies(token);
          window.scrollTo(0, 0);
          toast.success(response?.data?.message);
        } else {
          toast.error(response.error);
        }
        setIsLoading(false);
      } catch (error) {
        console.error("Error during form action:", error);
        setIsLoading(false);
      }
    }
  };

  // =====================================================================================================================
  // VIEW SINGLE ITEM FUNCTIONALITY
  // =====================================================================================================================
  const [isItemViewOpen, setIsItemViewOpen] = useState<boolean>(false);

  const handleViewItem = async (item: any) => {
    setCompany(item);
    setIsItemViewOpen(true);
  };

  // =====================================================================================================================
  // UPDATE FUNCTIONALITY
  // =====================================================================================================================
  const [isItemUpdate, setIsItemUpdate] = useState<boolean>(false);
  const [isExistImageDisplay, setIsExistImageDisplay] =
    useState<boolean>(false);

  const handleEditItem = async (item: any) => {
    setCompany(item);
    setFormData({
      name: item?.name,
      description: item?.description,
    });
    setIsAddFormOpen(true);
    setIsItemUpdate(true);
    setIsExistImageDisplay(true);
  };

  // =====================================================================================================================
  // DELETE FUNCTIONALITY
  // =====================================================================================================================
  const [isConfirmationOpen, setConfirmationOpen] = useState<boolean>(false);

  const handleConfirmDeleteItem = async (itemId: string) => {
    setCompanyId(itemId);
    setConfirmationOpen(true);
  };

  const handleDelete = async () => {
    try {
      setConfirmationOpen(false);
      setIsLoading(true);
      // Calling to the API service
      const response = await deleteCompany(token, companyId);
      // Handling the response
      if (response.success) {
        handleGetAllCompanies(token);
        toast.success(response?.data?.message);
      } else {
        toast.error(response.error);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error during form action:", error);
      setIsLoading(false);
    }
  };

  return (
    <React.Fragment>
      <div className="lg:px-24 lg:pt-12 min-h-screen">
        <h1 className="text-2xl text-slate-700 font-bold">Companies</h1>
        <div className="w-full flex justify-end items-center">
          {user?.role === UserRoles.SUPER_ADMIN ? (
            <button
              className="bg-blue-700 hover:bg-blue-800 text-white font-normal py-2 px-4 rounded-xl cursor-pointer text-sm"
              onClick={() => handleCreateItem()}
            >
              Add Company <i className="fa-solid fa-plus ml-2"></i>
            </button>
          ) : null}
        </div>
        <div className="w-full px-3 bg-slate-50 rounded-lg mt-10">
          <div
            className={` w-full h-14 mb-4 py-4 px-5 border-none rounded-lg  font-normal flex flex-row items-center justify-center`}
          >
            <i className={`fa-solid fa-magnifying-glass text-slate-400`}></i>
            <input
              className={`w-full ml-5 border-none outline-none bg-slate-50 text-slate-500`}
              type={"text"}
              name={"search_query"}
              placeholder={"Search Companies"}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e?.target?.value)}
              onKeyUp={(e) => handleSearchKeyUp(e)}
            />
          </div>
        </div>
        {companies?.length > 0 ? (
          <div className="relative overflow-x-auto shadow-xl sm:rounded-lg mt-16">
            <table className="w-full text-xs lg:text-sm text-left">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    name
                  </th>
                  <th scope="col" className="px-6 py-3">
                    description
                  </th>
                  <th scope="col" className="px-6 py-3">
                    User Count
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Options
                  </th>
                </tr>
              </thead>
              <tbody>
                {companies?.map((item: any) => (
                  <tr
                    className="bg-white border-b hover:bg-gray-50"
                    key={item._id}
                  >
                    <td className="px-6 py-4">
                      <h1 className="font-bold text-sm">{item?.name}</h1>
                    </td>
                    <td className="px-6 py-4 max-w-sm">
                      {truncateText(item?.description, 118)}
                    </td>
                    <td className="px-6 py-4">{item?.usersCount}</td>
                    <td className="px-6 py-4">
                      <div className="flex justify-start items-center">
                        {user?.role === UserRoles.SUPER_ADMIN ? (
                          <button
                            className="rounded-xl bg-rose-400 hover:bg-rose-500 p-2 text-white font-bold w-10 h-10 mx-1"
                            onClick={() => handleConfirmDeleteItem(item?._id)}
                          >
                            <i className="fa-regular fa-trash-can"></i>
                          </button>
                        ) : null}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="flex justify-center items-center w-full overflow-x-auto">
              <Paginator
                first={(currentPage - 1) * pageSize}
                rows={pageSize}
                totalRecords={total || 0}
                onPageChange={onPageChange}
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                prevPageLinkIcon={<p>Previous</p>}
                nextPageLinkIcon={<p>Next</p>}
                firstPageLinkIcon={null}
                lastPageLinkIcon={null}
              />
            </div>
          </div>
        ) : showNoContentMessage ? (
          <div className="w-full flex flex-col justify-center items-center mt-24">
            <span className="rounded-full h-64 w-64 bg-slate-50 flex flex-col justify-center items-center text-slate-300">
              <i className="fa-solid fa-circle-info text-8xl text-slate-200"></i>
              <h1 className="text-slate-300 mt-5 text-xs">
                No Companies available
              </h1>
            </span>
          </div>
        ) : null}
      </div>

      {/* Create form goes here */}
      <FormPopupComponent
        popUpDisplay={isAddFormOpen}
        handleClose={() => setIsAddFormOpen(false)}
        handleOpen={() => setIsAddFormOpen(true)}
        popUpTitle={"Add Company"}
      >
        <form className="w-full bg-slate-100 p-5 rounded-xl my-10">
          <div className="flex flex-wrap -mx-3">
            <div className="w-full px-3 mb-3">
              <FormInputField
                icon="fa-regular fa-building"
                type="text"
                label="Name *"
                placeholder="Enter company name"
                name="name"
                value={formData.name}
                onChange={(data: { name: string; value: any }) =>
                  handleInputChange(data.name, data.value)
                }
              />
            </div>
            <div className="w-full px-3 mb-3">
              <FormTextField
                label="Company Description *"
                name="description"
                value={formData.description}
                onChange={(data: { name: string; value: any }) =>
                  handleInputChange(data.name, data.value)
                }
                rows={8}
              />
            </div>
            <div className="w-full px-3 mb-6">
              <Button
                name={isItemUpdate ? "Update" : "Submit"}
                handleAction={handleFormAction}
              />
            </div>
          </div>
        </form>
      </FormPopupComponent>

      {/* Delete pop up goes here */}
      <PopupConfirmationComponent
        popUpDisplay={isConfirmationOpen}
        handleClose={() => setConfirmationOpen(false)}
        handleOpen={() => setConfirmationOpen(true)}
        popUpTitle={"Warning"}
        popUpContent={"Are you sure want to delete this company?"}
        handleConfirm={() => handleDelete()}
      />

      {isLoading ? <LoadingSpinner /> : null}
      <ToastContainer />
    </React.Fragment>
  );
}
