import React from "react";

export default function ResumeDetails({ data }: any) {
  if (!data)
    return (
      <p className="text-center text-gray-500">No resume data available</p>
    );

  return (
    <div className="resume-details bg-white p-8 rounded-lg shadow-md max-w-4xl mx-auto">
      <h2 className="text-2xl font-bold text-gray-900 text-center mb-6">
        All Resume Details
      </h2>

      {/* <p className="text-lg font-semibold text-gray-800 mb-4">
        <strong>Total Experience:</strong> {data.totalExperience} years
      </p> */}

      <section className="mb-6">
        <h3 className="text-xl font-semibold text-gray-700 mb-2">Key Skills</h3>
        <div className="flex flex-wrap gap-2">
          {data.keySkills.map((skill: any, index: any) => (
            <span
              key={index}
              className="inline-block px-3 py-1 text-xs font-normal text-slate-500 bg-slate-200 rounded-full"
            >
              {skill}
            </span>
          ))}
        </div>
      </section>

      <section className="mb-6">
        <h3 className="text-xl font-semibold text-gray-700 mb-2">
          Current Employment
        </h3>
        <p className="text-gray-600">
          <strong>Designation:</strong> {data.currentEmployment.designation}
        </p>
        <p className="text-gray-600">
          <strong>Company:</strong> {data.currentEmployment.companyName}
        </p>
        <p className="text-gray-600">
          <strong>Duration:</strong> {data.currentEmployment.duration}
        </p>
        <ul className="list-disc pl-5 space-y-1 text-gray-600 mt-2">
          {data.currentEmployment.responsibilities.map(
            (resp: any, index: any) => (
              <li key={index}>{resp}</li>
            )
          )}
        </ul>
      </section>

      <section className="mb-6">
        <h3 className="text-xl font-semibold text-gray-700 mb-2">
          Previous Experience
        </h3>
        {data.previousExperience.map((job: any, index: any) => (
          <div key={index} className="mb-4">
            <p className="text-gray-600">
              <strong>Designation:</strong> {job.designation}
            </p>
            <p className="text-gray-600">
              <strong>Company:</strong> {job.companyName}
            </p>
            <p className="text-gray-600">
              <strong>Duration:</strong> {job.duration}
            </p>
            <ul className="list-disc pl-5 space-y-1 text-gray-600 mt-2">
              {job.responsibilities.map((resp: any, idx: any) => (
                <li key={idx}>{resp}</li>
              ))}
            </ul>
          </div>
        ))}
      </section>

      <section className="mb-6">
        <h3 className="text-xl font-semibold text-gray-700 mb-2">Education</h3>
        {data.education.map((edu: any, index: any) => (
          <div key={index} className="mb-4">
            <p className="text-gray-600">
              <strong>Degree:</strong> {edu.degree}
            </p>
            <p className="text-gray-600">
              <strong>Institution:</strong> {edu.institution}
            </p>
            <p className="text-gray-600">
              <strong>Year:</strong> {edu.year}
            </p>
          </div>
        ))}
      </section>
    </div>
  );
}
