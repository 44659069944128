import React, { useEffect, useState } from "react";
import LoadingSpinner from "../../components/loading-spinner.component";
import { toast, ToastContainer } from "react-toastify";
import { useAuth } from "../../common/providers/user.provider";
import { UserRoles } from "../../common/enums/user-roles.enum";
import { useScrollToTop } from "../../common/hooks/use-scroll-to-top";
import { useNavigate } from "react-router-dom";
import useToken from "../../hooks/useToken";
import {
  formatDateAndTime,
  formatTimestamp,
  formatTimestampHtmlDateInput,
} from "../../common/utils/date-format";
import { Paginator } from "primereact/paginator";
import FormPopupComponent from "../../components/form-popup.component";
import FormInputField from "../../components/form-input.component";
import FormTextField from "../../components/form-text.component";
import Button from "../../components/button.component";
import { truncateText } from "../../common/utils/truncate-paragraghs";
import {
  createCompany,
  deleteCompany,
  getAllCompanies,
} from "../../services/comapnies-api.service";
import PopupConfirmationComponent from "../../components/popup-confirmation.component";
import { getAllCompaniesResumesList } from "../../services/resumes-api.service";
import { UploadTypeOptions } from "../../common/enums/upload-type.enum";
import { ResumeUploadedType } from "../../common/enums/resume-upload-types.enum";

export default function AdminResumesList() {
  useScrollToTop();
  const navigate = useNavigate();
  const { token, setToken, clearToken } = useToken();
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [resumesList, setResumesList] = useState<any | null>(null);
  const [company, setCompany] = useState<any | null>(null);
  const [companyId, setCompanyId] = useState<string>("");
  const [showNoContentMessage, setShowNoContentMessage] =
    useState<boolean>(false);

  // Handling the pagination
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(5);
  const [total, setTotal] = useState<number>(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortField, setSortField] = useState("createdAt");
  const [sortOrder, setSortOrder] = useState("asc"); // 'asc' or 'desc'

  //PrimeReact paginator handler
  const onPageChange = (event: any) => {
    const newPage = event.page + 1;
    const newPageSize = event.rows;
    setPageSize(newPageSize);
    setCurrentPage(newPage);
  };

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    const storedUser = JSON.parse(localStorage.getItem("user") || "");
    if (accessToken && (user || storedUser)) {
      handleGetAllCompaniesResumeList(accessToken);
    }
    const timeoutId = setTimeout(
      () => {
        setShowNoContentMessage(true);
      },
      resumesList?.length === 0 ? 0 : 100
    );
    return () => clearTimeout(timeoutId);
  }, [currentPage, pageSize]);

  // =====================================================================================================================
  // GET ALL ITEMS FUNCTIONALITY
  // =====================================================================================================================

  const handleGetAllCompaniesResumeList = async (token: any) => {
    try {
      setIsLoading(true);
      // Calling to the API service
      const response = await getAllCompaniesResumesList(
        token,
        currentPage,
        pageSize,
        searchQuery
      );
      // Handling the response
      if (response.success) {
        setResumesList(response?.data?.data?.items);
        console.log(
          "response?.data?.data?.items : ",
          response?.data?.data?.items
        );
        setTotal(response?.data?.data?.pageInfo?.totalDocuments);
      } else {
        toast.error(response.error);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error during form action:", error);
      setIsLoading(false);
    }
  };

  // =====================================================================================================================
  // SEARCH FUNCTIONALITY
  // =====================================================================================================================
  useEffect(() => {
    // Getting the token from the local storage
    const accessToken = localStorage.getItem("accessToken");

    // Stopping trigger this functionality when the page mount
    if (searchQuery.trim() === "") {
      handleGetAllCompaniesResumeList(accessToken);
    } else {
      const debounceSearch = setTimeout(() => {
        handleSearchAllCompaniesResumesList(accessToken);
      }, 750);
      return () => clearTimeout(debounceSearch);
    }
  }, [searchQuery]); // Only run this effect when searchQuery changes

  const handleSearchAllCompaniesResumesList = async (accessToken: any) => {
    try {
      setIsLoading(true);
      const response = await getAllCompaniesResumesList(
        accessToken,
        currentPage,
        pageSize,
        searchQuery
      );
      if (response.success) {
        setResumesList(response?.data?.data?.items);
        console.log(
          "response?.data?.data?.items : ",
          response?.data?.data?.items
        );
        setTotal(response?.data?.data?.pageInfo?.totalDocuments);
      } else {
        toast.error(response.error);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error during form action:", error);
      setIsLoading(false);
    }
  };

  const handleSearchKeyUp = (e: any) => {
    setSearchQuery(e.target.value);
  };

  // =====================================================================================================================
  // DELETE FUNCTIONALITY
  // =====================================================================================================================
  const [isConfirmationOpen, setConfirmationOpen] = useState<boolean>(false);

  const handleConfirmDeleteItem = async (itemId: string) => {
    setCompanyId(itemId);
    setConfirmationOpen(true);
  };

  const handleDelete = async () => {
    try {
      setConfirmationOpen(false);
      setIsLoading(true);
      // Calling to the API service
      const response = await deleteCompany(token, companyId);
      // Handling the response
      if (response.success) {
        handleGetAllCompaniesResumeList(token);
        toast.success(response?.data?.message);
      } else {
        toast.error(response.error);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error during form action:", error);
      setIsLoading(false);
    }
  };

  return (
    <React.Fragment>
      <div className="lg:px-24 lg:pt-12 min-h-screen">
        <h1 className="text-2xl text-slate-700 font-bold">All Resumes</h1>
        <div className="w-full px-3 bg-slate-50 rounded-lg mt-10">
          <div
            className={` w-full h-14 mb-4 py-4 px-5 border-none rounded-lg  font-normal flex flex-row items-center justify-center`}
          >
            <i className={`fa-solid fa-magnifying-glass text-slate-400`}></i>
            <input
              className={`w-full ml-5 border-none outline-none bg-slate-50 text-slate-500`}
              type={"text"}
              name={"search_query"}
              placeholder={"Search Resumes"}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e?.target?.value)}
              onKeyUp={(e) => handleSearchKeyUp(e)}
            />
          </div>
        </div>
        {resumesList?.length > 0 ? (
          <div className="relative overflow-x-auto shadow-xl sm:rounded-lg mt-16">
            <table className="w-full text-xs lg:text-sm text-left">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Candidate Name
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Email
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Phone Number
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Location
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Uploaded Type
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Uploaded At
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Options
                  </th>
                </tr>
              </thead>
              <tbody>
                {resumesList?.map((item: any) => (
                  <tr
                    className="bg-white border-b hover:bg-gray-50"
                    key={item._id}
                  >
                    <td className="px-6 py-4">
                      <h1 className="font-bold text-sm">
                        {item?.candidateName ? item?.candidateName : "N/A"}
                      </h1>
                    </td>
                    <td className="px-6 py-4 max-w-sm">
                      {item?.email ? item?.email : "N/A"}
                    </td>
                    <td className="px-6 py-4 max-w-sm">
                      {item?.phoneNumber ? item?.phoneNumber : "N/A"}
                    </td>
                    <td className="px-6 py-4 max-w-sm">
                      {item?.location ? item?.location : "N/A"}
                    </td>
                    <td className="px-6 py-4 max-w-sm">
                      {item?.uploaded_type ===
                      ResumeUploadedType?.UPLOADED_WITH_PDF ? (
                        // <>
                        //   {item?.file_format === ".pdf" ? (
                        //     <span className="inline-block bg-rose-400 text-white px-3 py-1 rounded-full text-xs">
                        //       {UploadTypeOptions?.PDF}
                        //     </span>
                        //   ) : item?.file_format === ".docx" ? (
                        //     <span className="inline-block bg-blue-400 text-white px-3 py-1 rounded-full text-xs">
                        //       {"DOC"}
                        //     </span>
                        //   ) : null}
                        // </>
                        <span className="inline-block bg-rose-400 text-white px-3 py-1 rounded-full text-xs">
                          {UploadTypeOptions?.PDF}
                        </span>
                      ) : item?.uploaded_type ===
                        ResumeUploadedType?.UPLOADED_WITH_CSV ? (
                        <span className="inline-block bg-green-400 text-white px-3 py-1 rounded-full text-xs">
                          {UploadTypeOptions?.CSV}
                        </span>
                      ) : item?.uploaded_type ===
                        ResumeUploadedType?.UPLOADED_WITH_TEXT ? (
                        <span className="inline-block bg-orange-300 text-white px-3 py-1 rounded-full text-xs">
                          {UploadTypeOptions?.TEXT}
                        </span>
                      ) : null}
                    </td>
                    <td className="px-6 py-4 max-w-sm">
                      {item?.createdAt
                        ? formatTimestamp(item?.createdAt)
                        : "N/A"}
                    </td>
                    <td className="px-6 py-4">
                      <div className="flex justify-start items-center">
                        {user?.role === UserRoles.SUPER_ADMIN ? (
                          <a
                            className="rounded-xl bg-blue-400 hover:bg-blue-500 p-2 text-white font-bold w-10 h-10 mx-1 text-center"
                            href={`${process.env.REACT_APP_S3_BUCKET_URL}/${item?.file}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i className="fa-regular fa-eye"></i>
                          </a>
                        ) : null}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="flex justify-center items-center w-full overflow-x-auto">
              <Paginator
                first={(currentPage - 1) * pageSize}
                rows={pageSize}
                totalRecords={total || 0}
                onPageChange={onPageChange}
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                prevPageLinkIcon={<p>Previous</p>}
                nextPageLinkIcon={<p>Next</p>}
                firstPageLinkIcon={null}
                lastPageLinkIcon={null}
              />
            </div>
          </div>
        ) : showNoContentMessage ? (
          <div className="w-full flex flex-col justify-center items-center mt-24">
            <span className="rounded-full h-64 w-64 bg-slate-50 flex flex-col justify-center items-center text-slate-300">
              <i className="fa-solid fa-circle-info text-8xl text-slate-200"></i>
              <h1 className="text-slate-300 mt-5 text-xs">
                No resumes available
              </h1>
            </span>
          </div>
        ) : null}
      </div>

      {/* Delete pop up goes here
      <PopupConfirmationComponent
        popUpDisplay={isConfirmationOpen}
        handleClose={() => setConfirmationOpen(false)}
        handleOpen={() => setConfirmationOpen(true)}
        popUpTitle={"Warning"}
        popUpContent={"Are you sure want to delete this company?"}
        handleConfirm={() => handleDelete()}
      /> */}

      {isLoading ? <LoadingSpinner /> : null}
      <ToastContainer />
    </React.Fragment>
  );
}
