import React from "react";
import { Link } from "react-router-dom";
import { VIEWS } from "../routes/routes";
import logoWhite from "../common/assets/images/logo-white.png";

export default function FooterDashboard() {
  return (
    <React.Fragment>
      <footer className="bg-footer w-full">
        <div className="w-full max-w-screen-xl mx-auto p-8">
          <div className="flex flex-col items-center justify-center pt-10">
            <Link
              to={VIEWS.HOME}
              className="text-2xl font-semibold whitespace-nowrap dark:text-white"
            >
              <img src={logoWhite} alt="" className="mx-auto sm:mx-0 h-10" />{" "}
            </Link>
            <span className="block text-sm text-gray-500 text-center mt-5 mb-2">
              © {new Date().getFullYear()}{" "}
              <Link to={VIEWS.HOME} className="hover:underline cursor-pointer">
                Vikalp
              </Link>
              . All Rights Reserved.
            </span>
          </div>
        </div>
      </footer>
    </React.Fragment>
  );
}
