import React from "react";

export default function Button(props: {
  name: string;
  handleAction?: any;
  isButtonDisabled?: boolean;
  type?: any;
  icon?: string;
}) {
  return (
    <React.Fragment>
      <button
        className={`w-full h-14 ${
          props.isButtonDisabled
            ? "bg-blue-300 hover:bg-blue-400 cursor-not-allowed"
            : "bg-blue-700 hover:bg-blue-800"
        }  text-white text-sm font-bold py-2 px-4 rounded-2xl  transition duration-300`}
        type={props.type ? props.type : "button"}
        onClick={props.handleAction}
        disabled={props.isButtonDisabled || false}
      >
        {props.name}{" "}
        {props?.icon ? <i className={`${props?.icon} ml-5`}></i> : ""}
      </button>
    </React.Fragment>
  );
}
