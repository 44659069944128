import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useAuth } from "../../common/providers/user.provider";
import InputField from "../../components/input-field.component";
import ProfileCard from "../../components/profile-card.component";
import { Paginator } from "primereact/paginator";
import { Dropdown } from "primereact/dropdown";
import { getAllResumes } from "../../services/resumes-api.service";
import userSearchIco from "../../common/assets/images/user_search.png";
import { useScrollToTop } from "../../common/hooks/use-scroll-to-top";
import { useNavigate, useSearchParams } from "react-router-dom";
import useToken from "../../hooks/useToken";
import FooterDashboard from "../../components/footer-dashboard.component";
import LoadingSpinner from "../../components/loading-spinner.component";
import { createSaveSearchRecord } from "../../services/saved-search-api.service";
import { SavedSearchType } from "../../common/enums/saved-search-types.enum";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import { getAllLocationsTypeList } from "../../services/types-api.service";
import KeywordInputInputNormalMandatory from "../../components/keyword-input-normal-mandatory.component";
import SimpleKeywordInput from "../../components/keyword-input-simple.component";

interface LocationOption {
  value: string;
  label: string;
}

export default function SearchResults() {
  useScrollToTop();
  const location = useLocation();
  const queryString = location.search;
  const navigate = useNavigate();
  const { token } = useToken();
  const { user } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [resumes, setResumes] = useState<any[] | null>([]);
  const [locationsTypeList, setLocationsTypeList] = useState<LocationOption[]>(
    []
  );

  // Handling the pagination
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(5);
  const [total, setTotal] = useState<number>(0);

  useEffect(() => {
    console.log("DATA RESUMES : ", resumes);
  }, [resumes]);

  // Filter states

  // Keywords filtering states
  // const [keywords, setKeywords] = useState(searchParams.get("keywords") || "");
  // const [mandatoryKeywords, setMandatoryKeywords] = useState(
  //   searchParams.get("mandatoryKeywords") || ""
  // );
  // const [excludeKeywords, setExcludeKeywords] = useState(
  //   searchParams.get("excludeKeywords") || ""
  // );
  const [keywords, setKeywords] = useState<string[]>([]);
  const [mandatoryKeywords, setMandatoryKeywords] = useState<string[]>([]);
  const [excludeKeywords, setExcludeKeywords] = useState<string[]>([]);

  // Locations filtering states
  const [locations, setLocations] = useState(
    searchParams.get("locations") || ""
  );
  // Experience filtering states
  const [minExperience, setMinExperience] = useState(
    searchParams.get("minExperience") || ""
  );
  const [maxExperience, setMaxExperience] = useState(
    searchParams.get("maxExperience") || ""
  );
  // Salary filtering states
  const [minSalary, setMinSalary] = useState(
    searchParams.get("minSalary") || ""
  );
  const [maxSalary, setMaxSalary] = useState(
    searchParams.get("maxSalary") || ""
  );
  // Company filtering states
  const [currentCompany, setCurrentCompany] = useState(
    searchParams.get("currentCompanyNames") || ""
  );
  const [previousCompany, setPreviousCompany] = useState(
    searchParams.get("previousCompanyNames") || ""
  );
  const [companyNames, setCompanyNames] = useState(
    searchParams.get("companyNames") || ""
  );
  const [companySearchOption, setCompanySearchOption] = useState(
    "currentCompanyNames"
  );
  const [companyInputValue, setCompanyInputValue] = useState("");

  // Designation filtering states
  const [currentDesignation, setCurrentDesignation] = useState(
    searchParams.get("currentDesignationNames") || ""
  );
  const [previousDesignation, setPreviousDesignation] = useState(
    searchParams.get("previousDesignationNames") || ""
  );
  const [designationNames, setDesignationNames] = useState(
    searchParams.get("designationNames") || ""
  );
  const [designationSearchOption, setDesignationSearchOption] = useState(
    "currentDesignationNames"
  );
  const [designationInputValue, setDesignationInputValue] = useState("");

  // Education Qualification Filters (UG, PG, PPG)
  // UG Qualification Filters
  const [selectedUGQualification, setSelectedUGQualification] = useState(
    searchParams.get("anyUGQualification")
      ? "Any UG qualification"
      : searchParams.get("noUGQualification")
      ? "No UG qualification"
      : "Specific UG qualification"
  );
  const [specificUGDegree, setSpecificUGDegree] = useState(
    searchParams.get("specificUGDegree") || ""
  );
  const [specificUGInstitution, setSpecificUGInstitution] = useState(
    searchParams.get("specificUGInstitution") || ""
  );

  // PG Qualification Filters
  const [selectedPGQualification, setSelectedPGQualification] = useState(
    searchParams.get("anyPGQualification")
      ? "Any PG qualification"
      : searchParams.get("noPGQualification")
      ? "No PG qualification"
      : "Specific PG qualification"
  );
  const [specificPGDegree, setSpecificPGDegree] = useState(
    searchParams.get("specificPGDegree") || ""
  );
  const [specificPGInstitution, setSpecificPGInstitution] = useState(
    searchParams.get("specificPGInstitution") || ""
  );

  // PPG Qualification Filters
  const [selectedPPGQualification, setSelectedPPGQualification] = useState(
    searchParams.get("anyPPGQualification")
      ? "Any PPG qualification"
      : searchParams.get("noPPGQualification")
      ? "No PPG qualification"
      : "Specific PPG qualification"
  );
  const [specificPPGDegree, setSpecificPPGDegree] = useState(
    searchParams.get("specificPPGDegree") || ""
  );
  const [specificPPGInstitution, setSpecificPPGInstitution] = useState(
    searchParams.get("specificPPGInstitution") || ""
  );

  // Display toggle states
  const [isShowKeywords, setIsShowKeywords] = useState(false);
  const [isShowExcludeKeywords, setIsShowExcludeKeywords] = useState(false);
  const [isShowLocations, setIsShowLocations] = useState(false);
  const [isShowExperience, setIsShowExperience] = useState(false);
  const [isShowCurrentCompany, setIsShowCurrentCompany] = useState(false);
  const [isShowCurrentDesignation, setIsShowCurrentDesignation] =
    useState(false);

  // New state for filters
  const [isFiltersApplied, setIsFiltersApplied] = useState(false);

  // Effect to set the states based on URL params
  useEffect(() => {
    const keywordsParam = searchParams.get("keywords") || "";
    const mandatoryKeywordsParam = searchParams.get("mandatoryKeywords") || "";
    const excludeKeywordsParam = searchParams.get("excludeKeywords") || "";

    // Convert the string of arrays into proper arrays
    setKeywords(
      keywordsParam ? decodeURIComponent(keywordsParam).split(",") : []
    );
    setMandatoryKeywords(
      mandatoryKeywordsParam
        ? decodeURIComponent(mandatoryKeywordsParam).split(",")
        : []
    );
    setExcludeKeywords(
      excludeKeywordsParam
        ? decodeURIComponent(excludeKeywordsParam).split(",")
        : []
    );
  }, [searchParams]);

  // Update companySearchOption based on presence of Company in URL parameters
  useEffect(() => {
    if (currentCompany) {
      setCompanySearchOption("currentCompanyNames");
      setCompanyInputValue(currentCompany);
    }
    if (previousCompany) {
      setCompanySearchOption("previousCompanyNames");
      setCompanyInputValue(previousCompany);
    }
    if (companyNames) {
      setCompanySearchOption("companyNames");
      setCompanyInputValue(companyNames);
    }
  }, [currentCompany, previousCompany, companyNames]);

  // Handle input value changes in company
  const handleCompanyInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value; // Extract value from the event
    setCompanyInputValue(value);
    if (companySearchOption === "currentCompanyNames") {
      setCurrentCompany(value);
    } else if (companySearchOption === "previousCompanyNames") {
      setPreviousCompany(value);
    } else if (companySearchOption === "companyNames") {
      setCompanyNames(value);
    }
  };

  // Update designationSearchOption based on presence of Designation in URL parameters
  useEffect(() => {
    if (currentDesignation) {
      setDesignationSearchOption("currentDesignationNames");
      setDesignationInputValue(currentDesignation);
    }
    if (previousDesignation) {
      setDesignationSearchOption("previousDesignationNames");
      setDesignationInputValue(previousDesignation);
    }
    if (designationNames) {
      setDesignationSearchOption("designationNames");
      setDesignationInputValue(designationNames);
    }
  }, [currentDesignation, previousDesignation, designationNames]);

  // Handle input value changes in designation
  const handleDesignationInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value; // Extract value from the event
    console.log("Designation Input : ", { value, designationSearchOption });
    setDesignationInputValue(value);
    if (designationSearchOption === "currentDesignationNames") {
      setCurrentDesignation(value);
    } else if (designationSearchOption === "previousDesignationNames") {
      setPreviousDesignation(value);
    } else if (designationSearchOption === "designationNames") {
      setDesignationNames(value);
    }
  };

  // Update URL params based on filter states
  useEffect(() => {
    const params: any = {
      keywords: keywords.join(","),
      mandatoryKeywords: mandatoryKeywords.join(","),
      excludeKeywords: excludeKeywords.join(","),
      locations,
      minExperience,
      maxExperience,
      minSalary,
      maxSalary,
      currentCompany,
      previousCompany,
      companyNames,
      currentDesignation,
      previousDesignation,
      designationNames,
      specificUGDegree,
      specificUGInstitution,
      specificPGDegree,
      specificPGInstitution,
      specificPPGDegree,
      specificPPGInstitution,
      page: currentPage.toString(),
      pageSize: pageSize.toString(),
    };

    // Add the selected qualification filters
    if (selectedUGQualification === "Any UG qualification") {
      params.anyUGQualification = true;
    } else if (selectedUGQualification === "No UG qualification") {
      params.noUGQualification = true;
    }

    if (selectedPGQualification === "Any PG qualification") {
      params.anyPGQualification = true;
    } else if (selectedPGQualification === "No PG qualification") {
      params.noPGQualification = true;
    }

    if (selectedPPGQualification === "Any PPG qualification") {
      params.anyPPGQualification = true;
    } else if (selectedPPGQualification === "No PPG qualification") {
      params.noPPGQualification = true;
    }

    // Remove any empty params before setting them
    Object.keys(params).forEach((key) => !params[key] && delete params[key]);

    setSearchParams(params);
  }, [
    keywords,
    mandatoryKeywords,
    excludeKeywords,
    locations,
    minExperience,
    maxExperience,
    minSalary,
    maxSalary,
    currentCompany,
    previousCompany,
    companyNames,
    currentDesignation,
    previousDesignation,
    designationNames,

    selectedUGQualification,
    specificUGDegree,
    specificUGInstitution,

    selectedPGQualification,
    specificPGDegree,
    specificPGInstitution,

    selectedPPGQualification,
    specificPPGDegree,
    specificPPGInstitution,

    currentPage,
    pageSize,
  ]);

  // Page size options
  const pageSizeOptions = [
    { label: "5", value: 5 },
    { label: "10", value: 10 },
    { label: "15", value: 15 },
    { label: "20", value: 20 },
    { label: "25", value: 25 },
    { label: "50", value: 50 },
    { label: "100", value: 100 },
  ];

  const applyFilters = () => {
    setIsFiltersApplied(true);
    setCurrentPage(1);
  };

  useEffect(() => {
    setIsFiltersApplied(true); // Trigger the initial fetch when component mounts
  }, []);

  useEffect(() => {
    if (isFiltersApplied) {
      const accessToken = localStorage.getItem("accessToken");
      const storedUser = JSON.parse(localStorage.getItem("user") || "");
      if (accessToken && (user || storedUser)) {
        handleGetAllLocationsTypeList(accessToken);
        handleGetAllResumes(accessToken);
        setIsFiltersApplied(false); // Reset the filter flag after applying
      }
    }
  }, [isFiltersApplied, currentPage, pageSize, user]);

  // Make sure that the selected option is being set correctly
  const selectedLocation = locationsTypeList.find(
    (option) => option.label === locations
  );

  // Handling the select options
  const handleSelectChange = (selectedOption: any) => {
    setLocations(selectedOption ? selectedOption.label : "");
  };

  const handleGetAllLocationsTypeList = async (token: any) => {
    try {
      setIsLoading(true);
      // Calling to the API service
      const response = await getAllLocationsTypeList(token);
      // Handling the response
      if (response.success) {
        const formattedLocations = response.data.data.map((company: any) => ({
          value: company._id,
          label: company.name,
        }));
        setLocationsTypeList(formattedLocations);
      } else {
        toast.error(response.error);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error during form action:", error);
      setIsLoading(false);
    }
  };

  const handleGetAllResumes = async (token: string) => {
    try {
      setIsLoading(true);

      const searchParams: any = {};
      if (keywords)
        searchParams.normalKeywords = Array.isArray(keywords)
          ? keywords
          : [keywords];
      if (mandatoryKeywords)
        searchParams.mandatoryKeywords = Array.isArray(mandatoryKeywords)
          ? mandatoryKeywords
          : [mandatoryKeywords];
      if (excludeKeywords)
        searchParams.excludeKeywords = Array.isArray(excludeKeywords)
          ? excludeKeywords
          : [excludeKeywords];

      if (locations) searchParams.locations = locations;
      if (minExperience) searchParams.minExperience = minExperience;
      if (maxExperience) searchParams.maxExperience = maxExperience;
      if (minSalary) searchParams.minSalary = minSalary;
      if (maxSalary) searchParams.maxSalary = maxSalary;

      // Company Filtering
      if (currentCompany) searchParams.currentCompanyNames = currentCompany;
      if (previousCompany) searchParams.previousCompanyNames = previousCompany;
      if (companyNames) searchParams.companyNames = companyNames;

      // Designation Filtering
      if (currentDesignation)
        searchParams.currentDesignationNames = currentDesignation;
      if (previousDesignation)
        searchParams.previousDesignationNames = previousDesignation;
      if (designationNames) searchParams.designationNames = designationNames;

      // Education Filters
      // UG Qualification
      if (selectedUGQualification === "Any UG qualification") {
        searchParams.anyUGQualification = true;
      } else if (selectedUGQualification === "No UG qualification") {
        searchParams.noUGQualification = true;
      } else if (selectedUGQualification === "Specific UG qualification") {
        if (specificUGDegree) searchParams.specificUGDegree = specificUGDegree;
        if (specificUGInstitution)
          searchParams.specificUGInstitution = specificUGInstitution;
      }

      // PG Qualification
      if (selectedPGQualification === "Any PG qualification") {
        searchParams.anyPGQualification = true;
      } else if (selectedPGQualification === "No PG qualification") {
        searchParams.noPGQualification = true;
      } else if (selectedPGQualification === "Specific PG qualification") {
        if (specificPGDegree) searchParams.specificPGDegree = specificPGDegree;
        if (specificPGInstitution)
          searchParams.specificPGInstitution = specificPGInstitution;
      }

      // PPG Qualification
      if (selectedPPGQualification === "Any PPG qualification") {
        searchParams.anyPPGQualification = true;
      } else if (selectedPPGQualification === "No PPG qualification") {
        searchParams.noPPGQualification = true;
      } else if (selectedPPGQualification === "Specific PPG qualification") {
        if (specificPPGDegree)
          searchParams.specificPPGDegree = specificPPGDegree;
        if (specificPPGInstitution)
          searchParams.specificPPGInstitution = specificPPGInstitution;
      }

      const response = await getAllResumes(
        token,
        currentPage,
        pageSize,
        searchParams
      );

      if (response.success) {
        setResumes(response?.data?.data?.resumes);
        setTotal(response?.data?.data?.total);
      } else {
        toast.error(response.error);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error during form action:", error);
      setIsLoading(false);
    }
  };

  const onPageChange = (event: any) => {
    setCurrentPage(event.page + 1); // Page is zero-indexed
    setPageSize(event.rows); // Ensure pageSize is updated properly
    setIsFiltersApplied(true); // Apply filters when page changes
  };

  const clearFilters = () => {
    setKeywords([]);
    setMandatoryKeywords([]);
    setExcludeKeywords([]);
    setLocations("");
    setMinExperience("");
    setMaxExperience("");
    setMinSalary("");
    setMaxSalary("");
    setCurrentCompany("");
    setPreviousCompany("");
    setCompanyNames("");
    setCompanyInputValue("");
    setCurrentDesignation("");
    setPreviousDesignation("");
    setDesignationNames("");
    setDesignationInputValue("");

    // Reset UG Qualification filters
    // setSelectedUGQualification("Any UG qualification");
    setSelectedUGQualification("");
    setSpecificUGDegree("");
    setSpecificUGInstitution("");

    // Reset PG Qualification filters
    // setSelectedPGQualification("Any PG qualification");
    setSelectedPGQualification("");
    setSpecificPGDegree("");
    setSpecificPGInstitution("");

    // Reset PPG Qualification filters
    // setSelectedPPGQualification("Any PPG qualification");
    setSelectedPPGQualification("");
    setSpecificPPGDegree("");
    setSpecificPPGInstitution("");

    setIsFiltersApplied(true); // Trigger re-fetch with cleared filters
    setCurrentPage(1); // Reset to first page
    setPageSize(5);
    setSearchParams((prevParams) => ({
      ...prevParams, // Retain other parameters
      page: 1, // Set current page to 1
      pageSize: 5, // Set default page size
    }));
  };

  /*===============================================================================
    ============================SAVE SEARCH GOES HERE===============================
    ================================================================================*/

  const [isOpenSavedSearchModel, setIsOpenSavedSearchModel] = useState(false);
  const [savedSearchName, setSavedSearchName] = useState("");

  const handleCreateSavedSearchRecord = async () => {
    try {
      setIsLoading(true);
      const saved_type = SavedSearchType.SAVED_SEARCH;

      const search_params = queryString;
      console.log("search_params : ", search_params);

      const search_name = savedSearchName;

      const response = await createSaveSearchRecord(
        token,
        saved_type,
        search_params,
        search_name
      );
      if (response.success) {
        toast.success(response.data?.message);
        setSavedSearchName("");
        setIsOpenSavedSearchModel(false);
      } else {
        toast.error(response.error);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error during form action:", error);
      setIsLoading(false);
    }
  };

  return (
    <React.Fragment>
      <div>
        <div className="h-32 bg-white shadow-xl w-full flex flex-row items-center justify-between lg:px-20">
          <div className="flex items-center justify-start">
            <img src={userSearchIco} className="w-7 h-7 mr-5" />
            <h3 className="font-bold text-lg">Advanced Search</h3>
            <i className="fa-solid fa-chevron-right ml-5"></i>
            <h3 className="font-normal text-lg ml-5">
              <span className="font-bold">
                {new Intl.NumberFormat("en-US").format(total)}
              </span>{" "}
              profiles found
            </h3>
          </div>
          {isOpenSavedSearchModel ? (
            <>
              <div className="my-10">
                <div className="flex items-center justify-center space-x-4 w-full">
                  <input
                    className="flex-grow py-4 px-5 rounded-lg font-normal border-primary-light border-2"
                    type="text"
                    placeholder="Enter name"
                    value={savedSearchName}
                    onChange={(e: any) => setSavedSearchName(e.target.value)}
                  />
                  <button
                    onClick={handleCreateSavedSearchRecord}
                    className="h-14 bg-blue-800 hover:bg-blue-700 text-white text-md font-bold py-2 px-8 rounded-2xl transition duration-300"
                  >
                    Save
                  </button>
                  <button
                    onClick={() => setIsOpenSavedSearchModel(false)}
                    className="h-14 bg-white border-2 border-blue-800 hover:border-blue-700 text-blue-800 text-md font-bold py-2 px-4 rounded-2xl transition duration-300"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </>
          ) : (
            <button
              onClick={() => setIsOpenSavedSearchModel(true)}
              className="w-fit h-14 bg-white border-2 border-blue-800 hover:border-blue-700 text-blue-800 text-md font-bold py-2 px-4 rounded-2xl transition duration-300"
            >
              Save This Search{" "}
              <i className="fa-regular fa-floppy-disk ml-2 font-bold text-xl"></i>
            </button>
          )}
        </div>
        <div className="lg:px-24 lg:pt-24 min-h-screen w-full flex">
          <div className="w-1/4 bg-slate-100 rounded-xl h-min-screen mx-4 px-8 py-12 mb-20">
            <h1 className="text-4xl text-slate-700 font-bold mb-12">Filters</h1>
            <div className="my-10 pt-2 pb-5 border-b-2 border-slate-300">
              {/* Keywords Filter */}
              <div
                className="flex justify-between items-center cursor-pointer"
                onClick={() => setIsShowKeywords(!isShowKeywords)}
              >
                <h1 className="text-xl font-bold text-slate-700">Keywords</h1>
                <i
                  className={`fa-solid ${
                    isShowKeywords ? "fa-chevron-up" : "fa-chevron-down"
                  }`}
                ></i>
              </div>
              {isShowKeywords && (
                <>
                  <div className="w-full flex flex-row justify-between items-center">
                    <KeywordInputInputNormalMandatory
                      initialKeywords={keywords}
                      initialMandatoryKeywords={mandatoryKeywords}
                      onUpdateKeywords={(
                        normal: string[],
                        mandatory: string[]
                      ) => {
                        setKeywords(normal);
                        setMandatoryKeywords(mandatory);
                      }}
                    />
                  </div>
                  <p
                    className="text-sm underline text-blue-800 cursor-pointer font-semibold mt-5"
                    onClick={() =>
                      setIsShowExcludeKeywords(!isShowExcludeKeywords)
                    }
                  >
                    Exclude Keywords
                  </p>
                  {isShowExcludeKeywords && (
                    <SimpleKeywordInput
                      initialKeywords={excludeKeywords}
                      onUpdateKeywords={(updatedKeywords) =>
                        setExcludeKeywords(updatedKeywords)
                      }
                    />
                  )}
                </>
              )}
            </div>
            {/* Locations Filter */}
            <div className="my-10 pt-2 pb-5 border-b-2 border-slate-300">
              <div
                className="flex justify-between items-center cursor-pointer"
                onClick={() => setIsShowLocations(!isShowLocations)}
              >
                <h1 className="text-xl font-bold text-slate-700">Locations</h1>
                <i
                  className={`fa-solid ${
                    isShowLocations ? "fa-chevron-up" : "fa-chevron-down"
                  }`}
                ></i>
              </div>
              {isShowLocations && (
                <>
                  <Select
                    className="basic-single mt-2"
                    classNamePrefix="select"
                    isLoading={isLoading}
                    isSearchable={true}
                    name="location"
                    options={locationsTypeList}
                    onChange={handleSelectChange}
                    value={selectedLocation}
                    isClearable={true}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        border: "2px solid #cbd5e1", // Matches the border color of the old component
                        borderRadius: "0.375rem", // Slight rounding for consistent design
                        padding: "0.25rem", // Padding similar to InputField
                        boxShadow: "none",
                        "&:hover": { borderColor: "#a0aec0" }, // Subtle hover effect
                      }),
                      valueContainer: (provided) => ({
                        ...provided,
                        padding: "0.25rem 0.5rem", // Inner padding to match the text input
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        fontSize: "1rem", // Match font size
                        color: "#4a5568", // Text color for consistency
                      }),
                      placeholder: (provided) => ({
                        ...provided,
                        color: "#a0aec0", // Placeholder color
                      }),
                      menu: (provided) => ({
                        ...provided,
                        borderRadius: "0.375rem", // Match dropdown style
                        boxShadow: "0 2px 6px rgba(0, 0, 0, 0.1)", // Subtle shadow for dropdown
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isSelected
                          ? "#e2e8f0"
                          : state.isFocused
                          ? "#edf2f7"
                          : "white",
                        color: "#2d3748", // Text color in options
                        cursor: "pointer",
                      }),
                    }}
                  />
                </>
              )}
            </div>

            {/* Experience Filter */}
            <div className="my-10 pt-2 pb-5 border-b-2 border-slate-300">
              <div
                className="flex justify-between items-center cursor-pointer"
                onClick={() => setIsShowExperience(!isShowExperience)}
              >
                <h1 className="text-xl font-bold text-slate-700">Experience</h1>
                <i
                  className={`fa-solid ${
                    isShowExperience ? "fa-chevron-up" : "fa-chevron-down"
                  }`}
                ></i>
              </div>
              {isShowExperience && (
                <div className="flex space-x-2 mt-4 justify-center items-center">
                  <InputField
                    type="number"
                    placeholder="Min Experience (years)"
                    value={minExperience}
                    onChange={(e: any) => setMinExperience(e.target.value)}
                    customClassName="flex-1"
                  />
                  <p className="mb-2">to</p>
                  <InputField
                    type="number"
                    placeholder="Max Experience (years)"
                    value={maxExperience}
                    onChange={(e: any) => setMaxExperience(e.target.value)}
                    customClassName="flex-1"
                  />
                  <p className="mb-2">years</p>
                </div>
              )}
            </div>

            {/* Current Company Filter */}
            <div className="my-10 pt-2 pb-5 border-b-2 border-slate-300">
              <div
                className="flex justify-between items-center cursor-pointer"
                onClick={() => setIsShowCurrentCompany(!isShowCurrentCompany)}
              >
                <h1 className="text-xl font-bold text-slate-700">Company</h1>
                <i
                  className={`fa-solid ${
                    isShowCurrentCompany ? "fa-chevron-up" : "fa-chevron-down"
                  }`}
                ></i>
              </div>
              {isShowCurrentCompany && (
                <>
                  <div className="input-field">
                    <InputField
                      icon="fa-solid fa-building text-slate-400"
                      type="text"
                      placeholder="Search company"
                      value={companyInputValue}
                      onChange={handleCompanyInputChange}
                    />
                  </div>
                  <p>
                    Search in :
                    <select
                      id="companySearchOption"
                      name="companySearchOption"
                      className="ml-2"
                      value={companySearchOption}
                      onChange={(e) => {
                        setCompanySearchOption(e.target.value);
                        if (currentCompany) {
                          setCurrentCompany("");
                          setCompanyInputValue("");
                        }
                        if (previousCompany) {
                          setPreviousCompany("");
                          setCompanyInputValue("");
                        }
                        if (companyNames) {
                          setCompanyNames("");
                          setCompanyInputValue("");
                        }
                      }}
                    >
                      <option value="currentCompanyNames">
                        Current Company
                      </option>
                      <option value="previousCompanyNames">
                        Previous Company
                      </option>
                      <option value="companyNames">
                        Current and Previous Company
                      </option>
                    </select>
                  </p>
                </>
              )}
            </div>

            {/* Current Designation Filter */}
            <div className="my-10 pt-2 pb-5 border-b-2 border-slate-300">
              <div
                className="flex justify-between items-center cursor-pointer"
                onClick={() =>
                  setIsShowCurrentDesignation(!isShowCurrentDesignation)
                }
              >
                <h1 className="text-xl font-bold text-slate-700">
                  Designation
                </h1>
                <i
                  className={`fa-solid ${
                    isShowCurrentDesignation
                      ? "fa-chevron-up"
                      : "fa-chevron-down"
                  }`}
                ></i>
              </div>
              {isShowCurrentDesignation && (
                <>
                  <div className="input-field">
                    <InputField
                      icon="fa-solid fa-briefcase text-slate-400"
                      type="text"
                      placeholder="Search designation"
                      value={designationInputValue}
                      onChange={handleDesignationInputChange}
                    />
                  </div>
                  <p>
                    Search in :
                    <select
                      id="designationSearchOption"
                      name="designationSearchOption"
                      className="ml-2"
                      value={designationSearchOption}
                      onChange={(e) => {
                        setDesignationSearchOption(e.target.value);
                        if (currentDesignation) {
                          setCurrentDesignation("");
                          setDesignationInputValue("");
                        }
                        if (previousDesignation) {
                          setPreviousDesignation("");
                          setDesignationInputValue("");
                        }
                        if (designationNames) {
                          setDesignationNames("");
                          setDesignationInputValue("");
                        }
                      }}
                    >
                      <option value="currentDesignationNames">
                        Current Designation
                      </option>
                      <option value="previousDesignationNames">
                        Previous Designation
                      </option>
                      <option value="designationNames">
                        Current and Previous Designation
                      </option>
                    </select>
                  </p>
                </>
              )}
            </div>
            <div className="w-full flex flex-col">
              <button
                className="w-full h-14 bg-blue-800 hover:bg-blue-700 text-white py-2 px-4 rounded-2xl my-2"
                onClick={applyFilters}
              >
                Apply Filters
              </button>
              <button
                className="w-full h-14 bg-gray-400 text-white py-2 px-4 rounded-2xl my-2"
                onClick={clearFilters}
              >
                Clear All Filters
              </button>
            </div>
          </div>
          <div className="w-3/4 h-min-screen">
            <div className="w-full flex flex-row justify-between items-center">
              <div className="flex flex-row justify-start items-center w-1/2">
                {resumes && resumes.length ? (
                  <Paginator
                    first={(currentPage - 1) * pageSize}
                    rows={pageSize}
                    totalRecords={total}
                    onPageChange={onPageChange}
                  />
                ) : null}
              </div>
              <div className="flex flex-row justify-end items-center w-1/2">
                {resumes && resumes.length ? (
                  <>
                    <p className="mr-2">Show:</p>
                    <Dropdown
                      value={pageSize}
                      options={pageSizeOptions}
                      onChange={(e) => {
                        setPageSize(e.value);
                        setCurrentPage(1); // Reset to first page on page size change
                        setIsFiltersApplied(true); // Apply filters when page size changes
                      }}
                      placeholder="Select Page Size"
                      className="custom-dropdown w-20 ml-2"
                    />
                  </>
                ) : null}
              </div>
            </div>

            <div className="flex flex-wrap justify-center lg:justify-start px-20">
              {isLoading ? (
                <div className="flex justify-center w-full h-full">
                  <LoadingSpinner />
                </div>
              ) : resumes && resumes.length ? (
                resumes.map((resume: any) => (
                  <ProfileCard
                    resumeId={resume?.resume_id}
                    candidateName={resume.candidateName}
                    currentEmployment={resume.currentEmployment}
                    previousExperience={resume.previousExperience}
                    education={resume.education}
                    location={resume.location}
                    keySkills={resume.keySkills}
                    profileSummary={resume.profileSummary}
                    phoneNumber={resume.phoneNumber}
                    totalExperience={resume.totalExperience}
                    key={resume.id} // Add a key prop for the map
                    file={resume.file}
                    email={resume.email}
                    updatedAt={resume.updatedAt}
                    salary={resume?.salary}
                    keywords={[...keywords, ...mandatoryKeywords]}
                  />
                ))
              ) : (
                <div className="flex flex-col justify-center items-center w-full mt-32">
                  <h1 className="text-slate-300 font-bold text-2xl">
                    Sorry, we couldn't find any resumes matching your search.
                  </h1>
                  <p className="text-slate-300 text-lg mt-8">
                    Please try adjusting your filters or search criteria and
                    search again.
                  </p>
                </div>
              )}
            </div>
            <div className="flex justify-center mt-5 mb-32">
              {resumes && resumes.length ? (
                <Paginator
                  first={(currentPage - 1) * pageSize}
                  rows={pageSize}
                  totalRecords={total}
                  onPageChange={onPageChange}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <FooterDashboard />
      <ToastContainer />
    </React.Fragment>
  );
}
