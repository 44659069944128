import React, { useEffect, useState } from "react";
import LoadingSpinner from "../../components/loading-spinner.component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InputField from "../../components/input-field.component";
import Button from "../../components/button.component";
import ChangePwForm from "../../components/dashboard/forms/change-pw-form.component";
import EditGeneralDetailsForm from "../../components/dashboard/forms/edit-general-details-form";
import DeleteAccountForm from "../../components/dashboard/forms/delete-account-form";
import { Link } from "react-router-dom";
import { VIEWS } from "../../routes/routes";
import ProfileImageUploadForm from "../../components/dashboard/forms/profile-image-upload-form";
import { useAuth } from "../../common/providers/user.provider";

export default function Settings() {
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <React.Fragment>
      <div className="lg:px-24 lg:pt-24 min-h-screen">
        <h1 className="text-2xl text-slate-700 font-bold">Account Settings</h1>
        <div className="overflow-hidden">
          <ProfileImageUploadForm />
          <EditGeneralDetailsForm />
          <ChangePwForm />
          <DeleteAccountForm />
        </div>
      </div>
      <div className="h-32"></div>
      {isLoading ? <LoadingSpinner /> : null}
      <ToastContainer />
    </React.Fragment>
  );
}
